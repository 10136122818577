<template>
   <li class="obi-dropdown-item">
      <a class="dropdown-item" :class="[wrapClass]" v-bind="attrs"><slot /></a>
   </li>
</template>

<script>
import { omit, keys } from 'lodash';

export default {
   name: 'ObiDropdownItem',
   props: ['wrapClass'],
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-dropdown-item {
}
</style>
